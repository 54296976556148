import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
import Register from '../layouts/Base/Register.vue';
import BaseDefault from '../layouts/Base/default.vue';
import MainLayout from '../layouts/MainLayout.vue';
import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';
import TransactionMonitoring from '../views/TransactionMonitoring.vue';
import UserVerify from '../views/UserVerification.vue';
import BudgetCreation from '../views/BudgetUtility.vue';
import PresLevelPPMP from '../views/PresLevelPPMP.vue';
import Consolidate from '../views/VPConsolidate.vue';
import ConsolidationSchedule from '../views/ConsolidationSchedule.vue';
import PRList from '../views/PSUApprovedPR.vue';
import POList from '../views/po-management/POList.vue';
import IARList from '../views/iar-management/IARManagement.vue';
import PPMPApproval from '../views/SignatoryPPMP.vue';
import POApproval from '../views/po-approval/FinanceSignatoryPO.vue';
import PRApproval from '../views/SignatoryPR.vue';
import ModuleList from '../views/ModuleManagement/Modules.vue';
import ModuleSubs from '../views/ModuleManagement/ModuleSubs.vue';
import UserModules from '../views/ModuleManagement/UserModules.vue';
import BudgetMonitoring from '../views/BudgetMonitoring.vue';
import ItemList from '../views/PSUItemList.vue';
import AllocatedBudgets from '../views/AllocatedBudgets.vue';
import RevisedBudgets from '../views/RevisedBudgets.vue';
import PPMPLIBList from '../views/PPMPLIBList.vue';
import PurchaseRequest from '../views/PSUPurchaseRequest.vue';
import PurchaseOrder from '../views/po-approval/PurchaseOrders.vue';
import PPMPRA from '../views/SignatoryPPMPRA.vue';
import APPManagement from '../views/APPManagement.vue';
import AOQManagement from '../views/AOQManagement.vue';
import Help from '../views/Help.vue';
import Settings from '../views/Settings.vue';
import Announcements from '../views/Announcements.vue';
import ApprovedPPMP from '../views/BudgetApprovedPPMP.vue';
import Position from '../views/Position.vue';
import User from '../views/User.vue';
import UserTransactionMonitoring from '../views/UserTransactionMonitoring.vue';
// import CreatePR from '../views/CreatePRModule.vue';
import UserPOList from '../views/po-approval/PurchaseOrders.vue';
import RIS from '../views/Supply/RequisitionAndIssuance.vue';
import ICS from '../views/Supply/InventoryCustodian.vue';
import PAR from '../views/Supply/PropertyAcknowledgement.vue';
import SigUtility from '../views/PSUSignatoriesUtility.vue';
import CompanyUtility from '../views/PSUCompanyUtility.vue';
import MOPUtility from '../views/PSUMOP.vue';
import PaymentTerm from '../views/PSUPaymentTerm.vue';
import DeliveryTerm from '../views/PSUDeliveryTermUtility.vue';
import PlaceOfDelivery from '../views/PSUPlaceOfDeliveryUtility.vue';
import DateOfDelivery from '../views/PSUDateOfDelivery.vue';
import Revision from '../views/Revision.vue';
import FiscalYear from '../views/FiscalYear.vue';
import FundUtility from '../views/FundUtility.vue';
import LIBList from '../views/LIBList.vue';
import AllotmentUtility from '../views/BudgetAllotmentUtility.vue';
import DeliveryUnitUtility from '../views/BudgetDeliveryUnitUtility.vue';
import OfficeUtility from '../views/BudgetOfficeCommitteeUtility.vue';
import FundSourceUtility from '../views/BudgetFundSourceUtility.vue';
import BudgetPurpose from '../views/BudgetPurposeUtility.vue';
import OtherIAR from '../views/iar-management/OtherIAR.vue';
import UploadedIAR from '../views/iar-management/UploadedIAR.vue';
import FundUtilizationRate from '../views/FundUtilizationRate.vue';
import ItemCategory from '../views/PSUItemCategory.vue';
import ItemClassification from '../views/ItemClassification.vue';
import ItemUtility from '../views/PSUItemUtility.vue';
import PriceInjection from '../views/PriceInjection.vue';
import UnitMeasure from '../views/UnitMeasure.vue';
import RISManagement from '../views/Supply/RISManagement.vue';
import ICSManagement from '../views/Supply/ICSManagement.vue';
import PARManagement from '../views/Supply/PARManagement.vue';
import RSManagement from '../views/Supply/RSManagement.vue';
import Inventory from '../views/Supply/Inventory.vue';
import InventoryPerPA from '../views/Supply/InventoryPerPA.vue';
import RevisedPPMP from '../views/BudgetRevisedPPMP.vue';
import RISApproval from '../views/Supply/SignatoryRIS.vue';
import ICSApproval from '../views/Supply/SignatoryICS.vue';
import PARApproval from '../views/Supply/SignatoryPAR.vue';
import RISAdminApproval from '../views/Supply/SignatoryRISAdmin.vue';
import ICSAdminApproval from '../views/Supply/SignatoryICSAdmin.vue';
import PARAdminApproval from '../views/Supply/SignatoryPARAdmin.vue';
import RSApproval from '../views/Supply/SignatoryRS.vue';
import RSAdminApproval from '../views/Supply/SignatoryRSAdmin.vue';
import UnitConversion from '../views/UnitConversion.vue';
import Renewal from '../views/Renewal.vue';
import ReturnSlip from '../views/Supply/ReturnSlip';
import MRUploading from '../views/Supply/MRUploading';
import RSMIManagement from '../views/Supply/RSMIManagement';
import SCManagement from '../views/Supply/SCManagement';
import SEPCManagement from '../views/Supply/SEPCManagement';
import PCManagement from '../views/Supply/PCManagement';
import RSPIManagement from '../views/Supply/RSPIManagement';
import UploadDocuments from '../views/Supply/UploadDocuments';
import TransferredItems from '../views/Supply/TransferredItems.vue';
import TransferredTo from '../views/Supply/TransferredTo.vue';
import TransferredFrom from '../views/Supply/TransferredFrom.vue';
import ReturnedItems from '../views/Supply/ReturnedItems.vue';
import DisposedItems from '../views/Supply/DisposedItems.vue';
import RSMIApproval from '../views/Accounting/SignatoryRSMI.vue';
import RSPIApproval from '../views/Accounting/SignatoryRSPI.vue';
import SuppliesLedgerCard from '../views/Accounting/SuppliesLedgerCard.vue';

const modules = [
    {
        path: '/dashboard',
        alias: '/',
        component: Dashboard,
    },
    {
        path: '/transaction-monitoring',
        component: TransactionMonitoring,
    },
    {
        path: '/budget-monitoring',
        component: BudgetMonitoring,
    },
    {
        path: '/user-verify',
        component: UserVerify
    },
    {
        path: '/budget-utility',
        component: BudgetCreation,
    },
    {
        path: '/approve-PPMP',
        component: PresLevelPPMP,
    },
    {
        path: '/consolidate',
        component: Consolidate,
    },
    {
        path: '/consolidation-schedule',
        component: ConsolidationSchedule,
    },
    {
        path: '/prlist',
        component: PRList,
    },
    {
        path: '/polist',
        component: POList,
    },
    {
        path: '/iarlist',
        component: IARList,
    },
    {
        path: '/approval-ppmp',
        component: PPMPApproval,
    },
    {
        path: '/approval-po',
        component: POApproval,
    },
    {
        path: '/approval-pr',
        component: PRApproval,
    },
    {
        path: '/module',
        component: ModuleList,
    },
    {
        path: '/module-sub',
        component: ModuleSubs,
    },
    {
        path: '/user-module',
        component: UserModules,
    },
    {
        path: '/itemlist',
        component: ItemList,
    },
    {
        path: '/allocated-budget',
        component: AllocatedBudgets,
    },
    {
        path: '/revised-budget',
        component: RevisedBudgets,
    },
    {
        path: '/ppmp-lib-list',
        component: PPMPLIBList,
    },
    {
        path: '/purchaserequest',
        component: PurchaseRequest,
    },
    {
        path: '/user-polist',
        component: PurchaseOrder,
    },
    {
        path: '/ra-ppmp',
        component: PPMPRA,
    },
    {
        path: '/app',
        component: APPManagement,
    },
    {
        path: '/aoq',
        component: AOQManagement,
    },
    {
        path: '/help',
        component: Help,
    },
    {
        path: '/approved-ppmp',
        component: ApprovedPPMP,
    },
    {
        path: '/position',
        component: Position,
    },
    {
        path: '/user',
        component: User,
    },
    {
        path: '/user-transaction-monitoring',
        component: UserTransactionMonitoring,
    },
    {
        path: '/user-budget-monitoring',
        component: BudgetMonitoring,
    },
    {
        path: '/itemlist',
        component: ItemList,
    },
    // {
    //     path: '/createpr',
    //     component: CreatePR,
    // },
    {
        path: '/user-polist',
        component: UserPOList,
    },
    {
        path: '/ris',
        component: RIS,
    },
    {
        path: '/ics',
        component: ICS,
    },
    {
        path: '/par',
        component: PAR,
    },
    {
        path: '/sig-utility',
        component: SigUtility,
    },
    {
        path: '/comp-utility',
        component: CompanyUtility,
    },
    {
        path: '/mop-utility',
        component: MOPUtility,
    },
    {
        path: '/pt-utility',
        component: PaymentTerm,
    },
    {
        path: '/dt-utility',
        component: DeliveryTerm,
    },
    {
        path: '/pd-utility',
        component: PlaceOfDelivery,
    },
    {
        path: '/dd-utility',
        component: DateOfDelivery,
    },
    {
        path: '/revision',
        component: Revision,
    },
    {
        path: '/fiscal-year',
        component: FiscalYear,
    },
    {
        path: '/fund-utility',
        component: FundUtility,
    },
    {
        path: '/lib-items',
        component: LIBList,
    },
    {
        path: '/allotment-utility',
        component: AllotmentUtility,
    },
    {
        path: '/delivery-unit-utility',
        component: DeliveryUnitUtility,
    },
    {
        path: '/office-committee-utility',
        component: OfficeUtility,
    },
    {
        path: '/purpose-utility',
        component: BudgetPurpose,
    },
    {
        path: '/fund-source-utility',
        component: FundSourceUtility,
    },
    {
        path: '/other-iar',
        component: OtherIAR,
    },
    {
        path: '/uploaded-iar',
        component: UploadedIAR,
    },
    {
        path: '/fund-rate',
        component: FundUtilizationRate,
    },
    {
        path: '/catutility',
        component: ItemCategory,
    },
    {
        path: '/classification',
        component: ItemClassification,
    },
    {
        path: '/item-utility',
        component: ItemUtility,
    },
    {
        path: '/price-injection',
        component: PriceInjection,
    },
    {
        path: '/unit-measure',
        component: UnitMeasure,
    },
    {
        path: '/ris-management',
        component: RISManagement,
    },
    {
        path: '/ics-management',
        component: ICSManagement,
    },
    {
        path: '/par-management',
        component: PARManagement,
    },
    {
        path: '/rs-management',
        component: RSManagement,
    },
    {
        path: '/inventory',
        component: Inventory,
    },
    {
        path: '/pa-inventory',
        component: InventoryPerPA,
    },
    {
        path: '/revised-ppmp',
        component: RevisedPPMP,
    },
    {
        path: '/ris-approval',
        component: RISApproval,
    },
    {
        path: '/ics-approval',
        component: ICSApproval,
    },
    {
        path: '/par-approval',
        component: PARApproval,
    },
    {
        path: '/ris-admin-approval',
        component: RISAdminApproval,
    },
    {
        path: '/ics-admin-approval',
        component: ICSAdminApproval,
    },
    {
        path: '/par-admin-approval',
        component: PARAdminApproval,
    },
    {
        path: '/rs-approval',
        component: RSApproval,
    },
    {
        path: '/rs-admin-approval',
        component: RSAdminApproval,
    },
    {
        path: '/unit-conversion',
        component: UnitConversion,
    },
    {
        path: '/renewal-schedule',
        component: Renewal,
    },
    {
        path: '/rs',
        component: ReturnSlip,
    },
    {
        path: '/mr-upload',
        component: MRUploading,
    },
    {
        path: '/rsmi',
        component: RSMIManagement,
    },
    {
        path: '/stock',
        component: SCManagement,
    },
    {
        path: '/semi-expendable',
        component: SEPCManagement,
    },
    {
        path: '/property',
        component: PCManagement,
    },
    {
        path: '/rspi',
        component: RSPIManagement,
    },
    {
        path: '/upload-docs',
        component: UploadDocuments,
    },
    {
        path: '/transferred-items',
        component: TransferredItems,
    },
    {
        path: '/transferred-from',
        component: TransferredTo,
    },
    {
        path: '/transferred-to',
        component: TransferredFrom,
    },
    {
        path: '/returned-items',
        component: ReturnedItems,
    },
    {
        path: '/disposed-items',
        component: DisposedItems,
    },
    {
        path: '/rsmi-approval',
        component: RSMIApproval,
    },
    {
        path: '/rspi-approval',
        component: RSPIApproval,
    },
    {
        path: '/slc',
        component: SuppliesLedgerCard,
    },
    
];

let user =  store.state.user;
let BasePath =  '/';
if(user){
    BasePath =  '/' + user.BaseUrl;
}
let paths = {path: BasePath,
meta: { 
    RouteForAdmin: user && user.roleID == 1 ? true : false,
    RouteForFM:  user && user.roleID == 2 ? true : false,
    RouteForPA:  user && user.roleID == 3 ? true : false,
    RouteForVP:  user && user.roleID == 4 ? true : false,
    RouteForPSU:  user && user.roleID == 5 ? true : false,
    RouteForSupply:  user && user.roleID == 6 ? true : false,
    RouteForBudget:  user && user.roleID == 7 ? true : false,
    RouteForPresident:  user && user.roleID == 8 ? true : false,
    RouteForFinance:  user && user.roleID == 9 ? true : false,
    RouteForInspectorate:  user && user.roleID == 15 ? true : false,
    RouteForRO:  user && user.roleID == 16 ? true : false,
    RouteForRTR:  user && user.roleID == 17 ? true : false,
},
component: MainLayout,
children: []};
if(store.state.module){
    store.state.module.forEach(item => {
        if(item.subLinks){
            item.subLinks.forEach(item2 => {
                modules.forEach(item3 => {
                    if(item3.path == item2.route){
                        paths.children.push(
                            {
                                path: BasePath + item2.route,
                                component: item3.component,
                            },
                        )
                        return false;
                    }
                });
            });
        }
        else{
            modules.forEach(item2 => {
                if(item2.path == item.route){
                    paths.children.push(
                        {
                            path: BasePath + item.route,
                            component: item2.component,
                        },
                    )
                    return false;
                }
            });
        }
        
    });
    paths.children.push(
        {
            path: BasePath + '/settings',
            component: Settings,
        },
        {
            path: BasePath + '/announcements',
            component: Announcements,
        },
    );
    // console.log(paths)
}


Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: BaseDefault,
        children: [
            {
                path: '/',
                component: Login,
            },
            {
                path: '/register',
                component: () =>
                    import ('../views/Register.vue'),
            },
            {
                path: '/forgot-password',
                component: () =>
                    import ('../views/Password.vue'),
            },
        ]
    },
    paths,
    {
        path: '/register/:email',
        name: 'OTP',
        component: Register
    },
    {
        path: '*',
        component: BaseDefault,
        children: [{
            path: '*',
            component: () =>
            import ('../components/Utils/ForbiddenAccess.vue'),
        }]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    let role = store.state.user;
    if (to.matched.some(record => record.meta.RouteForAdmin)) {
        // this route is for Admin Module
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 1) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForFM)) {
        // this route is for Fund Manager
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 2) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForPA)) {
        // this route is for Person Accountable
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 3) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForVp)) {
        // this route is for VP
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 4) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForPSU)) {
        // this route is for PSU
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 5) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForSupply)) {
        // this route is for Supply
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 6) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForBudget)) {
        // this route is for Budget Module
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 7) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForPresident)) {
        // this route is for Admin Module
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 8) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForFinance)) {
        // this route is for Finance Module
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 9) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForInspectorate)) {
        // this route is for Finance Module
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 15) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForRO)) {
        // this route is for Finance Module
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 16) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else if (to.matched.some(record => record.meta.RouteForRTR)) {
        // this route is for Finance Module
        // if not, redirect to login page.
        if (!role) {
            next({
                path: '/'
            })
        } else {
            if (role.roleID === 17) {
                next()
            } else {
                next({
                    path: '/'
                })
            }
        }
    }
    else {
        next()
    }
})

export default router