<template>
  <v-fade-transition mode="out-in">
    <router-view v-if="render" />
    <loading-screen v-else />
  </v-fade-transition>
</template>

<script>
export default {
  name: "App",
  components: {
    "loading-screen": () => import("./components/Utils/LoadingScreen.vue"),
  },
  created: function () {
    //VueX set this Vue Variable
    // this.$store.dispatch("setThisVue", this);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    if(token){
      localStorage.setItem("token", token);
      this.$router.replace("/");
      window.location.reload();
    }
    this.getUser();
  },
  data: () => ({
    render: false,
    data: null,
  }),
  methods: {
    getUser() {
      if (localStorage.getItem("token")) {
        // if(this.$store.state.user){
          this.render = false;
          this.axiosCall("/getUser", "GET")
          .then((res) => {
            if (res.data.data.token) {
              localStorage.setItem("token", res.data.data.token);
              this.data = res.data.data;
              if(this.data.roleID){
              this.$store.dispatch("setUser", res.data.data);
              this.$store.dispatch("setModule", res.data.data.module)
              if(this.$route.path == "/"){
                this.$router.push("/" + this.data.BaseUrl + "/dashboard");
                setTimeout(() => {
                  this.$router.go(0)
                }, 0);
              }
              }
            }
            else{
              localStorage.removeItem('token');
              this.$router.push('/');
            }
            setTimeout(() => {
            this.render = true;
            }, 1000);

          })
          .catch((error) => {
            console.log(error);
            localStorage.removeItem('token');
            this.$router.push('/');
            setTimeout(() => {
            this.render = true;
            }, 1000);
          });
        // }
      } else {
        this.render = true;
        this.$router.replace("/", () => {});
      }
    },
  },
};
</script>